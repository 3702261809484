export default {
    website_name: 'Make An Offer',
    texts: {
        heading: 'Let your customers make an offer',
        screenshot: require('./assets/images/make_an_offer.png'),
        subtitle: 'Let them offer a better price, choose to accept or decline - and in the process get their contact info for your marketing needs.',
        cta1: 'Get Started',
        cta_title: 'Never lose a customer because of price.',
        cta2: 'Get Started',
        features: [
            { name: 'Increase Conversion Rate', icon: require('./assets/images/feature-tile-icon-01.svg'), description: 'Don\'t lose customers over pricing. Let them make an offer, and choose to accept or decline, using automated rules or manually.' },
            { name: 'Fight Cart Abandonment', icon: require('./assets/images/feature-tile-icon-04.svg'), description: 'Activate notifications that show up when the customer is about to leave - requesting to make an offer.' },
            { name: 'Collect Customer Contact Info', icon: require('./assets/images/feature-tile-icon-02.svg'), description: 'The customer must leave their contact details to make an offer, so even if you decline their offer, you still get valuable contact info for your marketing needs.' },
            { name: 'Understand Your Customer\'s Price Points', icon: require('./assets/images/feature-tile-icon-03.svg'), description: 'By seeing what offers your customers make, you can better optimize your pricing.' },
            { name: 'Automated Responses', icon: require('./assets/images/feature-tile-icon-06.svg'), description: 'Based on pricing rules, you can decide to automatically accept or decline certain offers.' },
        ]
    },
    ga: {
        trackingId: 'UA-186289951-1',
        debug: true,
        gaOptions: {
            cookieDomain: 'none'
        }
    }
};
